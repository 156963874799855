import React from 'react';
import logo from '../images/logo.png';

function Footer() {
  return (
    <footer id="footer">
    {/* <div className="footer-newsletter">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>
              Tamen quem nulla quae legam multos aute sint culpa legam noster
              magna
            </p>
            <form action="" method="post">
              <input type="email" name="email" />
              <input type="submit" defaultValue="Subscribe" />
            </form>
          </div>
        </div>
      </div>
    </div> */}
    <div className="footer-top">
       <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-contact">
            <img src={logo} alt="logo" className="img-fluid" style={{
              width:"60%",
              height:"auto",
              marginBottom:"10px"
            }} />
            <p>
              Address - 83, Laxmi Nagar, <br/> Aurangabad (Sambhaji Nagar),<br/> MH, India - 431009
              <br />
              <strong>Phone:</strong> +91-7588399506
              <br />
              <strong>Email:</strong> info@marmaconsultancy.com
              <br />
            </p>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#hero">Home</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#about">About us</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#services">Services</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#">Terms of service</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#">Privacy policy</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#services">Data Services</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#services">ERP Integrations</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#services">CRM Integration</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#services">Change Management</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>
              Follow us on social media for latest updates and special offers
            </p>
            <div className="social-links mt-3">
              <a href="#" className="twitter">
                <i className="bx bxl-twitter" />
              </a>
              <a href="#" className="facebook">
                <i className="bx bxl-facebook" />
              </a>
              <a href="#" className="instagram">
                <i className="bx bxl-instagram" />
              </a>
              <a href="#" className="google-plus">
                <i className="bx bxl-skype" />
              </a>
              <a href="#" className="linkedin">
                <i className="bx bxl-linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-4 ">
      <div className="copyright">
        © 2024 Copyright{" "}
        <strong>
          <span>Marma Consultancy And Innovations</span>
        </strong>
        . All Rights Reserved
      </div>
    </div>
  </footer>
  )
}

export default Footer
