import React from 'react';


function About() {
  return (
    <>
    <section id="about" className="about container">
      <div className="container">
      <div className="section-title">
        <h2>About Us</h2>
        <p>
            Explore our mission
        </p>
      </div>
        <div className="row" style={{justifyContent: "center"}}>
          <div className="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img
              src="assets/img/about-img.svg"
              className="img-fluid"
              alt=""
              data-aos="zoom-in"
            />
          </div>
          <div className="col-lg-6 pt-5 pt-lg-4">
              <p data-aos="fade-up" data-aos-delay={100}>
              At MARMA, we're more than just consultants; we're your strategic tech allies. With years of experience and a passion for innovation, our team brings a unique blend of expertise and dedication to every project. We prioritize understanding your business to deliver personalized, high-impact solutions. Discover a partnership that seamlessly integrates with your operations, striving relentlessly for value addition.
              {/* <br/> <br/>
              We prioritize a deep understanding of your business, ensuring that our solutions are not just tailored but are also high-impact. Embrace a partnership with MARMA, where seamless integration with your operations is our priority, and relentless pursuit of value addition is our driving force. */}
            </p>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default About
