import React from 'react';
import erp from '../images/erp.png';
import crm from '../images/crm.png';
import dataservices from '../images/dataservices.png';
import changemanagement from '../images/changemanagement.png';

function Services() {
  return (
    <section id="services" className="services section-bg">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Services</h2>
        <p>Check out the great services we offer</p>
      </div>
      {/* <div className="row">
        <h6 className='services-desc'>
          Empower your business with our comprehensive range of tech consultancy services. From advanced data solutions to seamless ERP and CRM integrations, we've got you covered. Explore our services to find the perfect fit for your technology needs.
        </h6>
      </div> */}

      <div className="row rowcenter">
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={400}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bx-world" /> */}
              <img src={dataservices} alt="dataservices" className='img-icon'/>
            </div>
            <h4 className="title">
            Data Services
            </h4>
            <p className="description">
            We specialize in Reporting Infrastructure, Engineering Data Warehouses, Predictive Modeling, and Data Operations & Support. Our expertise ensures your data drives decision-making and growth.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={100}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bxs-window-alt" /> */}
              <img src={erp} alt="erp" className='img-icon' />
            </div>
            <h4 className="title">
            ERP Integrations
            </h4>
            <p className="description">
              Enhance your business efficiency with our ERP integration solutions. Specializing in Netsuite, SAP, and Workday, we provide seamless integrations and ongoing maintenance.
            </p>
          </div>
        </div>
      </div>
      <div className="row rowcenter">
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={300}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bx-tachometer" /> */}
              <img src={crm} alt="crm" className='img-icon'/>
            </div>
            <h4 className="title">
            CRM Integration
            </h4>
            <p className="description">
            Revolutionize your customer relationships with our CRM integration expertise. We offer development and maintenance services for Salesforce, Zoho, and Hubspot. Elevate your CRM experience to build stronger, more profitable customer connections
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={200}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bxs-cart-alt" /> */}
              <img src={changemanagement} alt="changemanagement" className='img-icon'/>
            </div>
            <h4 className="title">
            Change Management
            </h4>
            <p className="description">
            Navigate the complexities of system changes with ease. Our Change Management service is tailored to businesses involving Data Infrastructure, ERP, and CRM. We guide you through every step, ensuring a smooth transition and minimal disruption.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services
