import React from 'react';

function Hero() {
  return (
    <>
    <section id="hero" className="d-flex align-items-center">
    <div className="">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 className='head-title1'>
            Welcome To <span style={{color:"#1363d4",fontWeight:"bold"}}>MARMA</span>

          </h1>
          <h1 className='head-title'>
          Your Data & Tech Partner
          </h1>
          <h2>
          We transform technology challenges into growth opportunities. Our team of experts specializes in Data Management, ERP and CRM Integrations, and Change Management. Experience swift, no-nonsense solutions tailored to your business needs. Dive into a partnership that values action, transparency, and efficiency. Let's accelerate your success together!
          </h2>
          <div>
            <a href="#contact" className="btn-get-started scrollto">
              Contact Us
            </a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img
            src="https://bootstrapmade.com/demo/templates/Arsha/assets/img/hero-img.png"
            className="img-fluid animated"
            alt="hero-img"
          />
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Hero
