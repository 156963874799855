import React, { useEffect, useState } from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import About from "./components/About";
import Services from "./components/Services";
import Contactus from "./components/Contactus";
import Whyus from "./components/Whyus";


function App() {
  useEffect(() => {
    const backtotop = document.querySelector(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };

      window.addEventListener("load", toggleBacktotop);
      window.addEventListener("scroll", toggleBacktotop);

      return () => {
        window.removeEventListener("load", toggleBacktotop);
        window.removeEventListener("scroll", toggleBacktotop);
      };
    }
  }, []);

  return (
    <>
      <Nav />
      <Hero />
      <main id="main">
        <About />
        <Services />
        <Whyus/>
        <Contactus />
      </main>
      <Footer />
      <a
         href="#hero"
        className="back-to-top d-flex align-items-center justify-content-center scrollto"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </>
  );
}

export default App;
