import React, {useState} from 'react';
import emailjs from '@emailjs/browser';

function Contactus() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(name, email, message);
    if(name === "" || email === "" || message === "" || phoneNumber === "" || subject === ""){
        alert("Please fill all the fields");
        return;
    }

    emailjs.send('service_ua3uhul', 'template_f7j2ayc',{
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        subject: subject,
        message: message,
      }, 'm-dyrYtxkxVRG2kD9')
      .then((result) => {
         // console.log(result.text);
          alert("Message sent successfully, we will get back to you soon!");
      }, (error) => {
          //console.log(error.text);
          alert("Message not sent, please try again later!");
      });
      setName("");
      setEmail("");
      setPhoneNumber("");
      setSubject("");
      setMessage("");
   };

  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact Us</h2>
          <p>
            Please feel free to reach out to us for any queries
          </p>
        </div>
        <div className="row">
          <div
            className="col-lg-5 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt" />
                <h4>Location:</h4>
                <p>83, Laxmi Nagar, Aurangabad (Sambhaji Nagar), MH, India - 431009 </p>
              </div>
              <div className="email">
                <i className="bi bi-envelope" />
                <h4>Email:</h4>
                <p>
                  <a href='mailto:info@marmaconsultancy.com' target='_blank' rel='noreferrer' style={{
                    color: '#ab9d95'
                  }}>info@marmaconsultancy.com</a>
                </p>
              </div>
              
              <div className="phone">
                <i className="bi bi-phone" />
                <h4>Call:</h4>
                <p>
                  <a href='tel:+917588399506' target='_blank' rel='noreferrer' style={{
                    color: '#ab9d95'
                  }}>+91-7588399506</a>
                </p>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.5824892510377!2d75.3456812750482!3d19.857611181514578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdba27c33fdc507%3A0x80dc27e4e6f3b389!2s83%2C%20Laxmi%20Nagar%20Rd%2C%20Laxmi%20Nagar%2C%20Garkheda%2C%20Aurangabad%2C%20Maharashtra%20431009!5e0!3m2!1sen!2sin!4v1706619544229!5m2!1sen!2sin" 
                frameBorder={0}
                style={{ border: 0, width: "100%", height: 290 }}
                allowFullScreen=""
              />
            </div>
          </div>
          <div
            className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <form
              role="form"
              className="php-email-form"
            >
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Name: </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-6 mt-3 mt-md-0">
                  <label htmlFor="name">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="form-group col-md-12">
                  <label htmlFor="name">Phone Number:</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Phone Number"
                    required={true}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="name">Subject:</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required={true}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="name">Message:</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows={5}
                  required={true}
                  defaultValue={""}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button className='contact-submit' onClick={handleSubmit}>Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contactus
