import React from 'react';


function Whyus() {
  return (
    <>
    <section id="whyus" className="about container">
      <div className="container">
      <div className="section-title">
        <h2>Why Choose Us</h2>
        <p>
            Know more about our values
        </p>
      </div>
        <div className="row" style={{justifyContent: "center"}}>
          <div className="col-lg-5 pt-5 pt-lg-4">
              <p data-aos="fade-up" data-aos-delay={100}>
              Choose MARMA for a partnership that values prompt action and transparent communication. Our approach eliminates long waiting periods, offering real-time progress updates. Experience the right value at the right price, with a team that integrates into your operations to maximize value. 
              {/* <br/> <br/>
              Embark on a journey where you not only experience the right value at the right price but also witness seamless integration with a dedicated team that becomes an integral part of your operations. Our commitment is to deliver unparalleled value that aligns perfectly with your needs, ensuring that every investment you make translates into maximum efficiency and productivity. */}
            </p>
          </div>
          <div className="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img
              src="assets/img/growth.svg"
              className="img-fluid"
              alt=""
              data-aos="zoom-in"
            />
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Whyus;
